export default [
  {
    path: '/',
    name: 'default',
    redirect: { name: 'home' },
    component: () => import('@src/router/layouts/defaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@src/views/home-page/Index.vue'),
        children: [
          {
            path: '/modal/:modal',
            name: 'home-features-modal',
            component: () => import('@src/components/modals/FeatureDetailCarouselModal.vue'),
            props: true,
            meta: { position: true },
          },
        ],
        meta: { position: true },
      },

      {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@src/views/footer-pages/TermsOfServiceView.vue'),
      },
      {
        path: '/learn-more',
        name: 'learn-more',
        component: () => import('@src/components/misc/BannerLearnMore.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@src/views/footer-pages/PrivacyPolicyView.vue'),
      },
      {
        path: '/myqampus-faq',
        name: 'myqampus-faq',
        component: () => import('@src/views/faq/FAQ.vue'),
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import('@src/views/SignUpPage/signup.vue'),
      },
      {
        path: '/complete-registration',
        name: 'complete-registration',
        component: () => import('@src/views/SignUpPage/RegistrationCompletionView.vue'),
      },
      {
        path: '/pricing-details',
        name: 'pricing-details',
        component: () => import('@src/views/pricing_v2/PricingDetailsView.vue'),
      },
      {
        path: '/special-offers',
        name: 'special-offers',
        component: () => import('@src/views/pricing-page/SpecialDiscountPageView.vue'),
      },
      {
        path: '/offers',
        name: 'offers',
        component: () => import('@src/views/pricing-page/DiscountPage.vue'),
      },
      {
        path: '/input-range',
        name: 'input-range',
        component: () => import('@src/views/home-page/InputRange.vue'),
      },
      {
        path: '/pricing',
        name: 'pricing',
        component: () => import('@src/views/pricing_v2/PricingView.vue'),
      },
      {
        path: '/discounts',
        name: 'discounts',
        component: () => import('@src/views/special-pricing/DiscountsViews.vue'),
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/src/views/contact-us/ContactUsView.vue'),
      },
      {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/src/views/about-us/AboutUsView.vue'),
      },
      {
        path: '/features',
        redirect: { name: 'features' },
        children: [
          {
            path: ' ',
            name: 'features',
            component: () => import('@/src/views/features/Featuresview.vue'),
          },
          {
            path: '/features/modal/:modal',
            name: 'Features-modal',
            component: () => import('@src/components/modals/FeatureDetailCarouselModal.vue'),
            props: true,
            meta: { position: true },
          },
          {
            path: '/features/fee-manager',
            name: 'fee-manager',
            component: () => import('@src/views/features/FeeManager.vue'),
          },
          {
            path: '/features/attendance-manager',
            name: 'attendance-manager',
            component: () => import('@src/views/features/AttendanceManager.vue'),
          },
          {
            path: '/features/timetable-manager',
            name: 'timetable-manager',
            component: () => import('@src/views/features/TimeTableManager.vue'),
          },
          {
            path: '/features/syllabus-manager',
            name: 'syllabus-manager',
            component: () => import('@src/views/features/SyllabusManager.vue'),
          },
          {
            path: '/features/notification-and-reminder',
            name: 'notification-and-reminder',
            component: () => import('@src/views/features/NotificationReminder.vue'),
          },
          {
            path: '/features/roles-and-permissions',
            name: 'roles-and-permissions',
            component: () => import('@src/views/features/RolePermission.vue'),
          },
          {
            path: '/features/white-labelling',
            name: 'white-labelling',
            component: () => import('@src/views/features/WhiteLabelling.vue'),
          },
          {
            path: '/features/pickup-facility',
            name: 'pickup-facility',
            component: () => import('@src/views/features/PickupFacility.vue'),
          },
        ],
      },

      {
        path: '/refer',
        name: 'refer',
        component: () => import('@/src/views/footer-pages/ReferPage.vue'),
      },

      {
        path: '/affiliate',
        name: 'affiliate',
        component: () => import('@/src/views/footer-pages/Affiliate.vue'),
      },

      {
        path: '/brandAmbassador',
        name: 'brandAmbassador',
        component: () => import('@/src/views/Brand-ambassador/brandAmbassador.vue'),
      },

      {
        path: '/:catchAll(.*)',
        name: '404',
        redirect: { name: 'home' },
        component: () => import('@src/views/home-page/Index.vue'),
      },
    ],
  },
]

// import { app } from 'vue'
// function AsyncHandler(AsyncView) {
//   const AsyncHandler = () => ({
//     component: AsyncView,
//     // A component to use while the component is loading.
//     loading: require('../components/_loading.vue').default,
//     // Delay before showing the loading component.
//     // Default: 200 (milliseconds).
//     delay: 400,
//     // A fallback component in case the timeout is exceeded
//     // when loading the component.
//     error: require('../components/_timeout.vue').default,
//     // Time before giving up trying to load the component.
//     // Default: Infinity (milliseconds).
//     timeout: 10000,
//   })

//   // return Promise.resolve({
//   //   functional: true,
//   // })
// }
// AsyncHandler()()
